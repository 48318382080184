import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, } from "@angular/core";
import { Validators } from "@angular/forms";
import { NgbDatepickerConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as $ from "jquery";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import { PopUpComponent } from "../pop-up/pop-up.component";
import * as dayjs from "dayjs";
var ApplicationComponent = /** @class */ (function () {
    function ApplicationComponent(window, localStorage, app, router, rest, formDataService, formBuilder, seo, config, modalService, dialog, spinner, el, imageCompressor, route) {
        var _this = this;
        this.window = window;
        this.localStorage = localStorage;
        this.app = app;
        this.router = router;
        this.rest = rest;
        this.formDataService = formDataService;
        this.formBuilder = formBuilder;
        this.seo = seo;
        this.modalService = modalService;
        this.dialog = dialog;
        this.spinner = spinner;
        this.el = el;
        this.imageCompressor = imageCompressor;
        this.route = route;
        this.NIB3 = environment.featureNIB3;
        this.initialLoadDob = true;
        this.initialLoadIssue = true;
        this.initialLoadExpire = true;
        this.facePhotoMessage = '';
        this.facePassMessage = '';
        this.family_side_touch = false;
        this.family_member_names_touch = false;
        this.family_relation_touch = false;
        this.imageChangedEvent = "";
        this.imageChangedEventPass = "";
        this.imageChangedEventPassCover = "";
        this.croppedImage = "";
        this.croppedImagePass = "";
        this.croppedImagePassCover = "";
        this.cropperReady = false;
        this.cropperReadyPass = false;
        this.isIntendedError = false;
        this.isPhotoBigSize = false;
        this.isPassportBigSize = false;
        this.isCoverPassportBigSize = false;
        this.acceptTerm = false;
        this.isExpiredDate = false;
        this.selectedPhoneCode = "USA +1";
        this.isRequestCountry = false;
        this.hotel_doc_big_size = false;
        this.flight_doc_big_size = false;
        this.third_visa_doc_big_size = false;
        this.guarantor_visa_doc_big_size = false;
        this.hotel_warning_message = "";
        this.flight_warning_message = "";
        this.third_visa_warning_message = "";
        this.guarantor_warning_message = "";
        this.submitted = false;
        this.photo_count = 0;
        this.passport_count = 0;
        this.isPhotoSmallSize = false;
        this.isPassportSmallSize = false;
        this.isPassportCoverSmallSize = false;
        this.days = 0;
        this.except_message = "";
        this.next_2month = { year: 0, month: 0, day: 0 };
        this.minDate = { year: 0, month: 0, day: 0 };
        this.today = { year: 0, month: 0, day: 0 };
        this.citizens = "";
        this.isOrdinaryPassport = true;
        this.familySide = "";
        this.proofFileName = "";
        this.proofFileCount = 0;
        this.personRace = 0;
        this.popupCheckData = [];
        this.expDate = [];
        this.dateBirth = [];
        this.data = {
            session_id: "",
            input_id: "",
            input_value: "",
        };
        this.imgResultBeforeCompression = "";
        this.imgResultAfterCompression = "";
        this.setFormLocalStorage = {
            photoBase64: "",
            passportBase64: "",
            photoImg: "",
            passportImg: "",
            photoImgUrl: "",
            passportImgUrl: "",
            lastName: "",
            firstName: "",
            gender: "",
            dateBirth: { year: 0, month: 0, day: 0 },
            genderSelect: "",
            countryBirth: null,
            telephone: "",
            occupation: "",
            residentialAddress: "",
            stateProvince: "",
            countryCity: null,
            passportNum: "",
            dateIssue: { year: 0, month: 0, day: 0 },
            dateExpire: { year: 0, month: 0, day: 0 },
            countryPassport: null,
            intendDate: "",
            vehicle: "",
            purposeTrip: null,
            addressDuringVisit: "",
            nextDestination: "",
            checkExpDate: dayjs().format("YYYY-MM-DD"),
        };
        this.seo.addMetaTags();
        config.minDate = { year: 1900, month: 1, day: 1 };
        this.selectedLang = this.app.SelectedLang;
        setInterval(function () {
            console.log('Verify Token');
            var authInfo = JSON.parse(_this.localStorage.getItem(environment.localAuthenInfo));
            _this.verifyToken(authInfo.token);
        }, 300 * 1000);
    }
    ApplicationComponent.prototype.onFocus = function (event) {
        if (!environment.production) {
            console.log("FOCUS", "inputId: " + event.target.id + " = " + event.target.value);
        }
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = event.target.id;
        this.data.input_value = event.target.value;
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
    };
    ApplicationComponent.prototype.onBlur = function (event) {
        this[event.target.id + "_touch"] = true;
        if (!environment.production)
            console.log("BLUR", "inputId: " + event.target.id + " = " + event.target.value);
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = event.target.id;
        this.data.input_value = event.target.value;
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when unfocus input:", res);
        // });
    };
    ApplicationComponent.prototype.inputOnChange = function (event) {
        if (event.target.id === "tel") {
            var filterValues = this.filterValue(event.target.value);
            this.person.tel = filterValues;
        }
    };
    ApplicationComponent.prototype.filterValue = function (value) {
        return value.replace(/[^(0-9+)]+/g, "");
    };
    ApplicationComponent.prototype.convertFileToBase64 = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };
    ApplicationComponent.prototype.fileChangeEvent = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base64String;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("==>", event.target.files[0]);
                        if (!(event.target.files[0].size > 10485760)) return [3 /*break*/, 1];
                        this.updateSessionTrack(event.target.id, "photo_over_10_mb");
                        this.isPhotoBigSize = true;
                        return [2 /*return*/];
                    case 1:
                        this.isPhotoBigSize = false;
                        this.imageChangedEvent = event;
                        this.photo_count = 1;
                        return [4 /*yield*/, this.convertFileToBase64(event.target.files[0])
                            // const formData = new FormData();
                            // formData.append("upload_file_photo", `${base64String}`);
                        ];
                    case 2:
                        base64String = _a.sent();
                        // const formData = new FormData();
                        // formData.append("upload_file_photo", `${base64String}`);
                        this.rest.detectFace({ image: base64String }).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var faceDetect;
                            return tslib_1.__generator(this, function (_a) {
                                faceDetect = Array.isArray(result) ? result[0].faces : result.faces;
                                if (faceDetect.faces === 0) {
                                    this.facePhotoMessage = faceDetect.message;
                                }
                                else if (faceDetect.faces === 1) {
                                    this.facePhotoMessage = '';
                                }
                                else if (faceDetect.faces > 1) {
                                    this.facePhotoMessage = faceDetect.message;
                                }
                                console.log('facePhotoMessage', this.facePhotoMessage);
                                return [2 /*return*/];
                            });
                        }); });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationComponent.prototype.fileChangeEventPass = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base64String;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.target.files[0].size > 10485760)) return [3 /*break*/, 1];
                        this.updateSessionTrack(event.target.id, "passport_over_10_mb");
                        this.isPhotoBigSize = true;
                        this.isPassportBigSize = true;
                        return [2 /*return*/];
                    case 1:
                        this.imgPass = event.target.files[0];
                        this.isPassportBigSize = false;
                        this.imageChangedEventPass = event;
                        this.passport_count = 1;
                        return [4 /*yield*/, this.convertFileToBase64(event.target.files[0])
                            // const formData = new FormData();
                            // formData.append("upload_file_passport", `${base64String}`);
                        ];
                    case 2:
                        base64String = _a.sent();
                        // const formData = new FormData();
                        // formData.append("upload_file_passport", `${base64String}`);
                        this.rest.detectFace({ image: base64String }).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var faceDetect;
                            return tslib_1.__generator(this, function (_a) {
                                faceDetect = Array.isArray(result) ? result[0].faces : result.faces;
                                if (faceDetect.faces === 0) {
                                    this.facePassMessage = faceDetect.message;
                                }
                                else if (faceDetect.faces === 1) {
                                    this.facePassMessage = '';
                                }
                                else if (faceDetect.faces > 1) {
                                    this.facePassMessage = faceDetect.message;
                                }
                                console.log('facePassMessage', this.facePassMessage);
                                return [2 /*return*/];
                            });
                        }); });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationComponent.prototype.onRaceChange = function (event) {
        this.person.race = Number(event.value);
        if (event.value == 0) {
            this.person.race = 0;
            this.person.family_side = "";
            this.person.family_member_names = "";
            this.person.family_relation = "";
            this.family_side_touch = false;
            this.family_member_names_touch = false;
            this.family_relation_touch = false;
            this.removeProofFile();
        }
        else {
            this.person.race = 304;
        }
    };
    ApplicationComponent.prototype.proofFileChangeEvent = function (e) {
        var _this = this;
        this.proofFileCount = 1;
        var file = e.target.files[0];
        this.proofFileName = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _this.proofFile = reader.result;
            _this.proofFilePreview = reader.result;
            _this.person.proof_file_preview = reader.result.toString();
        };
    };
    ApplicationComponent.prototype.removeProofFile = function () {
        this.proofFile = "";
        this.proofFilePreview = "";
        this.proofFileName = "";
        this.proofFileCount = 0;
    };
    ApplicationComponent.prototype.onUploadProofFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("prooffile", this.proofFile);
                        if (!this.proofFile) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.rest.uploadProofFileService(formData)];
                    case 1:
                        result = _a.sent();
                        if (result.status == "success") {
                            this.person.proof_of_laotian = result.name;
                            this.personForm.value.proof_of_laotian = result.name;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationComponent.prototype.fileChangeEventPassCover = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.isPassportCoverBigSize = true;
            return;
        }
        else {
            this.isPassportCoverBigSize = false;
            this.imageChangedEventPassCover = event;
            this.passport_cover_count = 1;
        }
    };
    ApplicationComponent.prototype.cropperReadyLog = function () {
        console.log("Cropper ready");
    };
    ApplicationComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ApplicationComponent.prototype.imageCroppedPass = function (event) {
        this.croppedImagePass = event.base64;
    };
    ApplicationComponent.prototype.imageCroppedPassCover = function (event) {
        this.croppedImagePassCover = event.base64;
    };
    ApplicationComponent.prototype.imageLoaded = function () {
        this.cropperReady = true;
    };
    ApplicationComponent.prototype.imageLoadedPass = function () {
        this.cropperReadyPass = true;
    };
    ApplicationComponent.prototype.imageLoadedPassCover = function () {
        this.cropperReadyPassCover = true;
    };
    ApplicationComponent.prototype.loadImageFailed = function () {
        // console.log('Load failed');
    };
    ApplicationComponent.prototype.loadImageFailedPass = function () {
        // console.log('Load failed');
    };
    ApplicationComponent.prototype.loadImageFailedPassCover = function () {
        // console.log('Load failed');
    };
    ApplicationComponent.prototype.rotateLeft = function (img) {
        this[img + "Cropper"].rotateLeft();
    };
    ApplicationComponent.prototype.rotateRight = function (img) {
        this[img + "Cropper"].rotateRight();
    };
    ApplicationComponent.prototype.flipHorizontal = function (img) {
        this[img + "Cropper"].flipHorizontal();
    };
    ApplicationComponent.prototype.flipVertical = function (img) {
        this[img + "Cropper"].flipVertical();
    };
    ApplicationComponent.prototype.verifyToken = function (credential) {
        var _this = this;
        this.rest.verifyToken(credential).subscribe(function (result) {
            if (!result["auth"] &&
                result.message === "Failed to authenticate token.") {
                _this.router.navigate(["/apply"], { replaceUrl: true });
                localStorage.removeItem(environment.localAuthenInfo);
            }
            if (!result["auth"] && result.message === "TokenExpired") {
                _this.router.navigate(["/apply"], { replaceUrl: true });
                localStorage.removeItem(environment.localAuthenInfo);
            }
            if (!result["responseStatus"]) {
                localStorage.removeItem(environment.localAuthenInfo);
                _this.router.navigate(["/apply"], { replaceUrl: true });
            }
            else {
                localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
                console.log('Back to Applicaiton form page');
                // this.router.navigate(["/application"]);
            }
        }, function (error) {
            console.error(error);
            localStorage.removeItem(environment.localAuthenInfo);
            _this.router.navigate(["/apply"], { replaceUrl: true });
        });
    };
    ApplicationComponent.prototype.getCountryList = function () {
        var _this = this;
        this.rest.getCountry().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // TODO: changed on v2
                // this.mDataArrayCountry = result.country;
                this.mDataArrayCountry = result;
                return [2 /*return*/];
            });
        }); });
    };
    ApplicationComponent.prototype.getCountryBirthList = function () {
        var _this = this;
        this.rest.getCountryBirth().subscribe(function (result) {
            // TODO: changed on v2
            // this.mDataArrayCountryBirth = result.country;
            _this.mDataArrayCountryBirth = result;
        });
    };
    ApplicationComponent.prototype.getVisaTypeList = function () {
        var _this = this;
        this.rest.getVisaType().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, result];
                    case 1:
                        _a.mDataArrayVisaType = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        console.log("mDataArrayVisaType 2", this.mDataArrayVisaType);
    };
    ApplicationComponent.prototype.getPortEntryList = function () {
        var _this = this;
        this.rest.getPortEntry().subscribe(function (result) {
            _this.mDataArrayPortEntry = result;
            _this.filteredPortOfEntry = result;
        });
    };
    ApplicationComponent.prototype.getPurposeList = function () {
        var _this = this;
        this.rest.getPurpose().subscribe(function (result) {
            _this.mPurpose = result;
        });
    };
    ApplicationComponent.prototype.getCallingCodeList = function () {
        var _this = this;
        this.rest.getCallingCode().subscribe(function (result) {
            _this.mDataArrayCallingCode = result;
        });
    };
    ApplicationComponent.prototype.getExceptMessage = function () {
        var _this = this;
        this.rest.getExceptMessage().subscribe(function (result) {
            setTimeout(function () {
                _this.except_message = result["except_message"];
            });
        });
    };
    ApplicationComponent.prototype.getPersonInfo = function (headers) {
        this.rest.checkExistData(headers).subscribe(function (rs) { });
    };
    ApplicationComponent.prototype.deletePerson = function (application_id) {
        var id = application_id[0].application_id;
        this.rest.deletePerson(id).subscribe(function (result) { });
    };
    ApplicationComponent.prototype.readURL = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                if (event.target.getAttribute("name") === "inputPassportFile") {
                    _this.person.imgPass = e.target.result;
                }
                else if (event.target.getAttribute("name") === "inputPhotoFile") {
                    _this.person.imgPhoto = e.target.result;
                }
            };
            reader.readAsDataURL(file);
            // reader.readAsDataURL(event.target.files[0]);
        }
    };
    ApplicationComponent.prototype.save = function (form) {
        if (!form.valid) {
            return false;
        }
        this.person.first_action_date = "";
        this.formDataService.setPerson(this.person);
        this.router.navigate(["/review"]);
        return true;
    };
    ApplicationComponent.prototype.goToNext = function (form) {
        if (this.save(form)) {
            // Navigate to the work page
            this.router.navigate(["/review"]);
        }
    };
    ApplicationComponent.prototype.IntendedOnChange = function (event) {
        this.intendedDate = event;
        this.validateIntendedDate(event);
        console.log('Intended: ', event);
    };
    ApplicationComponent.prototype.validateIntendedDate = function (event) {
        if (this.expiredDate && this.intendedDate) {
            var inten = new Date(this.intendedDate.year, this.intendedDate.month - 1, this.intendedDate.day);
            var ex = new Date(this.expiredDate.year, this.expiredDate.month - 1, this.expiredDate.day);
            var intended = moment(inten);
            var expired = moment(ex);
            var diff = expired.diff(intended, "months");
            if (diff >= 6) {
                this.isIntendedError = false;
            }
            else {
                // if(this.app.SelectedLang == 'en'){
                this.intentedMessage =
                    "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
                this.intentedMessage_cn =
                    "护照有效期不得少于6个月，将从入境老挝之日起开始计算。入境日期不得晚于申请电子签证后60天。建议您至少在出发前 5 天提出申请。";
                this.intentedMessage_fr =
                    "The passport should have minimum validity of 6 months from the date of arrival in Laos.";
                this.isIntendedError = true;
                return;
            }
        }
        if (this.intendedDate) {
            var inten = new Date(this.intendedDate.year, this.intendedDate.month - 1, this.intendedDate.day);
            var intended = moment(inten);
            var now = new Date();
            var checkintenDate = intended.diff(now, "days") + 1;
            // console.log('check if intended not more 7 day ' + checkintenDate);
            if (checkintenDate < 5) {
                // if (this.intendedDate.day > 0) {
                // if(this.app.SelectedLang == 'en'){
                this.intentedMessage =
                    "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
                this.intentedMessage_cn =
                    "护照自抵达老挝之日起至少应有 6 个月的有效期。 您的预计入境日期应在电子签证申请之日起 60 天内。 建议您至少在出发前 5 天提出申请。";
                this.intentedMessage_fr =
                    "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
                this.isIntendedError = true;
                // }
                return;
            }
            else {
                this.isIntendedError = false;
            }
        }
    };
    ApplicationComponent.prototype.DateBirthOnChange = function (event) {
        if (this.initialLoadDob) {
            this.initialLoadDob = false;
            return;
        }
        this.setFormLocalStorage.dateBirth = event;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        this.person.DOB = event;
    };
    ApplicationComponent.prototype.ExpiredOnChange = function (event) {
        if (this.initialLoadExpire) {
            this.initialLoadExpire = false;
            return;
        }
        this.setFormLocalStorage.dateExpire = event;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        this.person.expiredDate = event;
        this.expiredDate = event;
        var curent_date = new Date();
        var ex = new Date(this.expiredDate.year, this.expiredDate.month - 1, this.expiredDate.day);
        var now = moment(curent_date);
        var expired = moment(ex);
        var diff = expired.diff(now, "days");
        if (diff <= 0) {
            if (this.expiredDate.day > 0) {
                this.isExpiredDate = true;
            }
        }
        else {
            this.isExpiredDate = false;
            this.validateIntendedDate(event);
        }
    };
    ApplicationComponent.prototype.IssueOnChange = function (event) {
        if (this.initialLoadIssue) {
            this.initialLoadIssue = false;
            return;
        }
        this.issueDate = event;
        this.setFormLocalStorage.dateIssue = event;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        if (this.issueDate && this.expiredDate) {
            var curent_date = new Date();
            var ex = new Date(this.expiredDate.year + 10, this.expiredDate.month - 1, this.expiredDate.day);
            var now = moment(curent_date);
            var expired = moment(ex);
            var diff = expired.diff(now, "days");
            if (diff <= 0) {
                if (this.expiredDate.day > 0) {
                    this.isExpiredDate = true;
                }
            }
            else {
                this.isExpiredDate = false;
                this.validateIntendedDate(event);
            }
        }
    };
    ApplicationComponent.prototype.onCountryBirthChange = function (selectedId) {
        this.setFormLocalStorage.countryBirth = Number(selectedId);
        this.onSetToLocalStorage(this.setFormLocalStorage);
        console.log("Country of birth:", selectedId);
        var s = this.mDataArrayCountryBirth.find(function (x) { return x.country_id == selectedId; });
        this.person.selectedCountryBirth = s["country_name"];
        console.log("Country Birth:", s);
        // this.updateSessionTrack("countryOfBirth", `${selectedId}`);
    };
    ApplicationComponent.prototype.onPurposeChange = function (selectedId) {
        console.log("Purpose of trip: ", selectedId);
        this.setFormLocalStorage.purposeTrip = Number(selectedId);
        this.onSetToLocalStorage(this.setFormLocalStorage);
        var s = this.mPurpose.find(function (x) { return x.purpose_id == selectedId; });
        console.log(s);
        this.person.selectedPurpose = s["purpose_name"];
        // this.updateSessionTrack("purposeOfTrip", `${selectedId}`);
    };
    ApplicationComponent.prototype.onGenderChange = function (event) {
        var selectedOptions = event.target["options"];
        var selectedIndex = selectedOptions.selectedIndex;
        var selectElementText = selectedOptions[selectedIndex].text;
        this.person.selectedGender = selectElementText;
        this.setFormLocalStorage.gender = selectedOptions[selectedIndex].value;
        this.setFormLocalStorage.genderSelect = selectElementText;
        this.onSetToLocalStorage(this.setFormLocalStorage);
        console.log(event.target["options"]);
        // this.updateSessionTrack("gender", selectElementText);
    };
    ApplicationComponent.prototype.onCountryOfCityChange = function (selectedId, event, key) {
        if (key == "countryCity") {
            this.setFormLocalStorage.countryCity = Number(selectedId);
        }
        else {
            this.setFormLocalStorage.countryPassport = Number(selectedId);
        }
        this.onSetToLocalStorage(this.setFormLocalStorage);
        console.log("Data Country of City:", selectedId);
        this.updateSessionTrack("countryOfCity", "" + selectedId);
        if (selectedId !== "") {
            var s = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            this.person.selectedCountryCity = s["country_name"];
            this.person.selectedCountryOfPassport = s["country_name"];
            this.citizens = s["citizens"];
            this.days = s["stay_day"];
            var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            if (country.is_except == 1) {
                event.srcElement.blur();
                event.preventDefault();
                this.openLg(this.content);
            }
            if (country.request_attach === 1) {
                this.person.is_request_doc = true;
            }
            else if (country.request_attach == 0) {
                this.person.is_request_doc = false;
            }
        }
        else {
            this.person.is_request_doc = false;
        }
        // If selected Taiwan (167), hide Boten Railway (53)
        if (selectedId == 167) {
            this.filteredPortOfEntry = this.mDataArrayPortEntry.filter(function (item) { return item.port_of_entry_id != 53; });
        }
        else {
            this.filteredPortOfEntry = this.mDataArrayPortEntry;
        }
    };
    ApplicationComponent.prototype.openLg = function (content) {
        this.modalService.open(content, { size: "lg", centered: true });
    };
    ApplicationComponent.prototype.onCountryOfPassportChange = function (selectedId, event) {
        console.log("Country Passport:", selectedId);
        if (selectedId !== "") {
            if (this.selectedCountry) {
                this.selectedCountry = selectedId;
            }
            var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            this.person.selectedCountryOfPassport = country["country_name"];
            if (country.is_except === 1) {
                event.srcElement.blur();
                event.preventDefault();
                this.openLg(this.content);
            }
            if (country.request_attach === 1) {
                this.person.is_request_doc = true;
            }
            else if (country.request_attach == 0) {
                this.person.is_request_doc = false;
            }
        }
        else {
            this.person.is_request_doc = false;
        }
        // If selected Taiwan (167), hide Boten Railway (53)
        if (selectedId == 167) {
            this.filteredPortOfEntry = this.mDataArrayPortEntry.filter(function (item) { return item.port_of_entry_id != 53; });
        }
        else {
            this.filteredPortOfEntry = this.mDataArrayPortEntry;
        }
    };
    ApplicationComponent.prototype.onVisaTypeChange = function (selectedId) {
        // this.updateSessionTrack("visaType", `${selectedId}`);
        var s = this.mDataArrayVisaType.find(function (x) { return x.visa_type_id == selectedId; });
        this.person.selectedvisaType = s["visa_type_name"];
        // if (this.personRace == 304) {
        //   const s: string = this.mDataArrayVisaType.find(
        //     (x: any) => x.visa_type_id == 2
        //   );
        //   this.person.visaType = "2";
        //   this.person.selectedvisaType = s["visa_type_name"];
        // } else {
        //   const s: string = this.mDataArrayVisaType.find(
        //     (x: any) => x.visa_type_id == selectedId
        //   );
        //   this.person.visaType = "1";
        //   this.person.selectedvisaType = s["visa_type_name"];
        // }
    };
    ApplicationComponent.prototype.onPortOfEntryChange = function (selectedId) {
        this.updateSessionTrack("portOfEntry", "" + selectedId);
        var s = this.mDataArrayPortEntry.find(function (x) { return x.port_of_entry_id == selectedId; });
        this.person.selectedportOfEntry = s["port_of_entry_name"];
        // Before 2023-04-07 If select Boten Railway Station, intended date must be >= 13/04/2023
        if (moment().isBefore("2023-04-07") && selectedId == 53) {
            this.minDate.year = Number(moment("2023-04-13").format("YYYY"));
            this.minDate.month = Number(moment("2023-04-13").format("MM"));
            this.minDate.day = Number(moment("2023-04-13").format("DD"));
            this.person.intendedDate = { year: 0, month: 0, day: 0 };
        }
        else {
            this.minDate.year = Number(moment().add(5, "d").format("YYYY"));
            this.minDate.month = Number(moment().add(5, "d").format("MM"));
            this.minDate.day = Number(moment().add(5, "d").format("DD"));
            // reset intended date
            this.person.intendedDate = { year: 0, month: 0, day: 0 };
        }
    };
    ApplicationComponent.prototype.onSubmit = function (form) {
        console.log('Person Form:', this.person);
        this.submitted = true;
        // if (!this.custImage.imgPhoto
        //   || this.facePhoto === 0 || this.facePhoto > 1 || this.facePhotoMessage.length > 0
        // ) {
        //   return;
        // }
        // if (!this.custImage.imgPass
        //   || this.facePassport === 0 || this.facePassport > 1 || this.facePassMessage.length > 0
        // ) {
        //   return;
        // }
        if (!this.person.imgPhotoName) {
            this.custImage.imgPhoto = null;
            return;
        }
        if (!this.person.imgPassName) {
            this.custImage.imgPass = null;
            return;
        }
        if (!this.person.countryCity) {
            return;
        }
        if (this.person.is_request_doc === true) {
            if (this.person.hotel_doc === "" || this.hotel_doc_big_size === true) {
                return;
            }
            if (this.person.flight_doc === "" || this.flight_doc_big_size === true) {
                return;
            }
            if (this.person.third_visa_doc === "" ||
                this.third_visa_doc_big_size === true) {
                return;
            }
            if (this.person.hotel_doc === "" ||
                this.guarantor_visa_doc_big_size === true) {
                return;
            }
            if (this.person.bank_statement_doc === "" ||
                this.bank_statement_doc_big_size === true) {
                return;
            }
        }
        else if (this.save(form)) {
            this.router.navigate(["/review"]);
        }
    };
    ApplicationComponent.prototype.checkAccept = function () {
        this.acceptTerm = !this.acceptTerm;
    };
    ApplicationComponent.prototype.onUploadPhotoImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // this.person.imgPhoto = this.croppedImage;
                        this.custImage.imgPhoto = this.croppedImage;
                        formData = new FormData();
                        formData.append("upload_file_photo", this.custImage.imgPhoto);
                        formData.append("version", "2");
                        return [4 /*yield*/, this.rest.uploadPhotoFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (Array.isArray(result)) {
                                        this.person.imgPhotoName = result[0].name;
                                        this.setFormLocalStorage.photoImg = result[0].name;
                                        this.setFormLocalStorage.photoImgUrl = environment.node_image_url + "/photo/" + dayjs().format('YYYYMMDD') + "/" + result[0].name;
                                        this.onSetToLocalStorage(this.setFormLocalStorage);
                                        console.log('==><==', result[0]);
                                        this.facePhoto = result[0].faces;
                                    }
                                    else {
                                        this.person.imgPhotoName = result.name;
                                        this.setFormLocalStorage.photoImg = result.name;
                                        this.setFormLocalStorage.photoImgUrl = environment.node_image_url + "/photo/" + dayjs().format('YYYYMMDD') + "/" + result.name;
                                        this.onSetToLocalStorage(this.setFormLocalStorage);
                                        console.log('==><==', result);
                                        this.facePhoto = result.faces;
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationComponent.prototype.onUploadPassportImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event, formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        setTimeout(function () { return _this.spinner.hide(); }, 3000);
                        // this.person.imgPass = this.croppedImagePass;
                        this.custImage.imgPass = this.croppedImagePass;
                        this.mrzPass = this.imgPass;
                        event = this.imageChangedEventPass;
                        formData = new FormData();
                        // this.setFormLocalStorage.passportBase64 = this.croppedImagePass;
                        // this.onSetToLocalStorage(this.setFormLocalStorage);
                        formData.append("upload_file_passport", this.custImage.imgPass);
                        return [4 /*yield*/, this.rest.uploadPassportFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    // console.log(result.name);
                                    // console.log(result.mrz);
                                    this.person.imgPassName = result.name;
                                    this.setFormLocalStorage.passportImg = result.name;
                                    this.setFormLocalStorage.passportImgUrl = environment.node_image_url + "/passport/" + dayjs().format('YYYYMMDD') + "/" + result.name;
                                    this.onSetToLocalStorage(this.setFormLocalStorage);
                                    setTimeout(function () {
                                        _this.updateSessionTrack("upload_passport", "upload_passport_success");
                                    }, 1500);
                                    if (result.mrz !== null) {
                                        if (result.mrz.status == "success") {
                                            this.mrzData = result.mrz.data.passport;
                                            if (this.mrzData.lastName)
                                                this.person.lastName = this.mrzData.lastName;
                                            this.setFormLocalStorage.lastName = this.mrzData.lastName;
                                            if (this.mrzData.firstName)
                                                this.person.firstName = this.mrzData.firstName;
                                            this.setFormLocalStorage.firstName = this.mrzData.firstName;
                                            if (this.mrzData.passportNumber)
                                                this.person.passportNumber = this.mrzData.passportNumber;
                                            this.setFormLocalStorage.passportNum = this.mrzData.passportNumber;
                                            if (this.mrzData.sex)
                                                this.person.gender = ("" + this.mrzData.sex).toLowerCase();
                                            if (result.mrz.data.passport)
                                                this.expDate = result.mrz.data.passport.expirationDate.split("-");
                                            if (this.mrzData.birthDate)
                                                this.dateBirth = this.mrzData.birthDate.split("-");
                                            if (this.mrzData.countryOfCitizenshipId)
                                                this.person.countryCity = this.mrzData.countryOfCitizenshipId;
                                            if (this.mrzData.countryOfPassportId)
                                                this.person.countryOfPassport = this.mrzData.countryOfPassportId;
                                            this.ExpiredOnChange({
                                                year: parseInt(this.expDate[0]),
                                                month: parseInt(this.expDate[1]),
                                                day: parseInt(this.expDate[2]),
                                            });
                                            this.DateBirthOnChange({
                                                year: parseInt(this.dateBirth[0]),
                                                month: parseInt(this.dateBirth[1]),
                                                day: parseInt(this.dateBirth[2]),
                                            });
                                            this.onCountryOfCityChange(this.mrzData.countryOfCitizenshipId, "", "nothing");
                                            this.onCountryOfPassportChange(this.mrzData.countryOfPassportId, "");
                                        }
                                    }
                                    if (result.faces != null) {
                                        console.log('==><==', result.faces);
                                        this.facePassport = result.faces;
                                    }
                                    return [2 /*return*/];
                                });
                            }); })
                            // this.mrzDetection(this.mrzPass)
                        ];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // mrzDetection(image) {
    //   this.spinner.show();
    //   setTimeout(() => this.spinner.hide(), 3000);
    //   const formdata = new FormData();
    //   formdata.append('image', image);
    //   this.rest.ocrMRZDetection(formdata).subscribe(async (result) => {
    //     if (result.status === 'failed') {
    //       console.log('Result from MRZ:', result);
    //       return
    //     }
    //     if (result.status === 'success') {
    //       this.mrzData = await result.data.passport;
    //       this.person.lastName = this.mrzData.lastName;
    //       this.person.firstName = this.mrzData.firstName;
    //       this.person.passportNumber = this.mrzData.passportNumber;
    //       this.person.gender = `${this.mrzData.sex}`.toLowerCase();
    //       this.expDate = await result.data.passport.expirationDate.split('-');
    //       this.dateBirth = this.mrzData.birthDate.split('-');
    //       this.person.countryCity = this.mrzData.countryOfCitizenshipId;
    //       this.person.countryOfPassport = this.mrzData.countryOfPassportId;
    //     }
    //     this.ExpiredOnChange({ year: parseInt(this.expDate[0]), month: parseInt(this.expDate[1]), day: parseInt(this.expDate[2]) });
    //     this.DateBirthOnChange({ year: parseInt(this.dateBirth[0]), month: parseInt(this.dateBirth[1]), day: parseInt(this.dateBirth[2]) })
    //     this.onCountryOfCityChange(this.mrzData.countryOfCitizenshipId, '');
    //     this.onCountryOfPassportChange(this.mrzData.countryOfPassportId, '');
    //   });
    // }
    ApplicationComponent.prototype.onUploadPassportCoverImage = function () {
        var _this = this;
        // this.person.imgPass = this.croppedImagePass;
        this.custImage.imgCover = this.croppedImagePassCover;
        var event = this.imageChangedEventPassCover;
        var formData = new FormData();
        formData.append("upload_file_passport_cover", this.custImage.imgCover);
        this.rest.uploadPassportCoverFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.person;
                        return [4 /*yield*/, result[0].name];
                    case 1:
                        _a.imgCoverName = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ApplicationComponent.prototype.onFileHotelChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.hotel_doc_big_size = true;
        }
        else {
            this.hotel_doc_big_size = false;
            this.selectedFile = event.target.files[0];
            this.person.hotel_doc = event.target.files[0].name;
            // console.log(this.person.hotel_doc);
            this.onUpload();
        }
    };
    ApplicationComponent.prototype.onFileFlightChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.flight_doc_big_size = true;
        }
        else {
            this.flight_doc_big_size = false;
            this.selectedFileFlight = event.target.files[0];
            this.onUploadFlight();
        }
    };
    ApplicationComponent.prototype.onFileThirdVisaChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.third_visa_doc_big_size = true;
        }
        else {
            this.third_visa_doc_big_size = false;
            this.selectedFileThirdVisa = event.target.files[0];
            this.person.third_visa_doc = event.target.files[0].name;
            this.onUploadThirdVisa();
        }
    };
    ApplicationComponent.prototype.onFileGuarantorInputUploadChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.guarantor_visa_doc_big_size = true;
        }
        else {
            this.guarantor_visa_doc_big_size = false;
            this.selectedFileGuarantor = event.target.files[0];
            this.person.guarantor_doc = event.target.files[0].name;
            this.onUploadGuarantor();
        }
    };
    ApplicationComponent.prototype.onFileBankStatementChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.bank_statement_doc_big_size = true;
        }
        else {
            this.bank_statement_doc_big_size = false;
            this.selectedFileBankStatement = event.target.files[0];
            this.person.bank_statement_doc = event.target.files[0].name;
            this.onUploadBankStatement();
        }
    };
    ApplicationComponent.prototype.onUpload = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("hotel_file", this.selectedFile, this.selectedFile.name);
        this.rest.uploadHoteldoc(uploadData).subscribe(function (result) {
            _this.person.hotel_doc = result.name;
        });
    };
    ApplicationComponent.prototype.onUploadFlight = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("flight_file", this.selectedFileFlight, this.selectedFileFlight.name);
        this.rest.uploadFlightdoc(uploadData).subscribe(function (result) {
            _this.person.flight_doc = result.name;
        });
    };
    ApplicationComponent.prototype.onUploadThirdVisa = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("third_visa_file", this.selectedFileThirdVisa, this.selectedFileThirdVisa.name);
        this.rest.uploadThirdVisadoc(uploadData).subscribe(function (result) {
            _this.person.third_visa_doc = result.name;
        });
    };
    ApplicationComponent.prototype.onUploadGuarantor = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("guarantor_file", this.selectedFileGuarantor, this.selectedFileGuarantor.name);
        this.rest.uploadGuarantordoc(uploadData).subscribe(function (result) {
            _this.person.guarantor_doc = result.name;
        });
    };
    ApplicationComponent.prototype.onUploadBankStatement = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("bank_statement_file", this.selectedFileBankStatement, this.selectedFileBankStatement.name);
        this.rest.uploadBankStatementdoc(uploadData).subscribe(function (result) {
            _this.person.bank_statement_doc = result.name;
        });
    };
    Object.defineProperty(ApplicationComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.personForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ApplicationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        console.log('Person Form:', this.person);
        var authInfo = JSON.parse(this.localStorage.getItem(environment.localAuthenInfo));
        var checkValidDate = this.setFormLocalStorage.checkExpDate;
        setTimeout(function () {
            var currentDate = dayjs();
            var dayDiff = currentDate.diff(checkValidDate, "days");
            if (dayDiff > 7) {
                _this.localStorage.removeItem("previousData");
            }
            else {
                _this.person.lastName = _this.setFormLocalStorage.lastName;
                _this.person.firstName = _this.setFormLocalStorage.firstName;
                _this.person.selectedGender = _this.setFormLocalStorage.genderSelect;
                _this.person.gender = _this.setFormLocalStorage.gender;
                // this.custImage.imgPhoto = this.setFormLocalStorage.photoBase64;
                // this.custImage.imgPass = this.setFormLocalStorage.passportBase64;
                // this.reUploadPhoto(this.custImage.imgPhoto);
                // this.reUploadPassport(this.custImage.imgPass);
                _this.person.imgPhotoName = _this.setFormLocalStorage.photoImg;
                _this.person.imgPassName = _this.setFormLocalStorage.passportImg;
                _this.DateBirthOnChange(_this.setFormLocalStorage.dateBirth);
                _this.IssueOnChange(_this.setFormLocalStorage.dateIssue);
                _this.ExpiredOnChange(_this.setFormLocalStorage.dateExpire);
                _this.person.tel = _this.setFormLocalStorage.telephone;
                _this.person.occupation = _this.setFormLocalStorage.occupation;
                _this.person.resAddress = _this.setFormLocalStorage.residentialAddress;
                _this.person.province = _this.setFormLocalStorage.stateProvince;
                _this.person.issueDate = _this.setFormLocalStorage.dateIssue;
                _this.person.passportNumber = _this.setFormLocalStorage.passportNum;
                _this.person.vehicle = _this.setFormLocalStorage.vehicle;
                _this.person.addressDuringVisit =
                    _this.setFormLocalStorage.addressDuringVisit;
                _this.person.next_destination = _this.setFormLocalStorage.nextDestination;
            }
        }, 2000);
        if (authInfo) {
            this.person.token = authInfo.token;
            this.verifyToken(authInfo.token);
            this.getPersonInfo(authInfo.token);
            this.getCountryList();
            this.getCountryBirthList();
            this.getVisaTypeList();
            this.getPortEntryList();
            this.getCallingCodeList();
            this.getPurposeList();
            this.getExceptMessage();
            var localAuth = localStorage.getItem("localAuthenInfo");
            setTimeout(function () {
                _this.person.DOB = _this.setFormLocalStorage.dateBirth;
                if (_this.setFormLocalStorage.countryBirth !== null) {
                    var s = _this.mDataArrayCountryBirth.find(function (x) { return x.country_id === _this.setFormLocalStorage.countryBirth; });
                    _this.person.countryBirth = s["country_id"];
                    _this.person.selectedCountryBirth = s["country_name"];
                }
                if (_this.setFormLocalStorage.countryCity !== null) {
                    var s = _this.mDataArrayCountry.find(function (x) { return x.country_id === _this.setFormLocalStorage.countryCity; });
                    _this.person.countryCity = s["country_id"];
                    _this.person.selectedCountryCity = s["country_name"];
                }
                if (_this.setFormLocalStorage.countryPassport !== null) {
                    var s = _this.mDataArrayCountry.find(function (x) {
                        return x.country_id === _this.setFormLocalStorage.countryPassport;
                    });
                    _this.person.countryOfPassport = s["country_id"];
                    _this.person.selectedCountryOfPassport = s["country_name"];
                }
                if (_this.setFormLocalStorage.purposeTrip !== null) {
                    var s = _this.mPurpose.find(function (x) { return x.purpose_id === _this.setFormLocalStorage.purposeTrip; });
                    _this.person.purposeOfTrip = s["purpose_id"];
                    _this.person.selectedPurpose = s["purpose_name"];
                }
                if (_this.person.countryCity == '167' || _this.person.countryOfPassport == '167') {
                    _this.filteredPortOfEntry = _this.mDataArrayPortEntry.filter(function (item) { return item.port_of_entry_id != 53; });
                }
                else {
                    _this.filteredPortOfEntry = _this.mDataArrayPortEntry;
                }
            }, 3000);
            if (localAuth !== null && localAuth !== "null") {
                var authUser = JSON.parse(localAuth);
                var token = authUser["token"];
                var payload = token.split(".")[1];
                var payloadObj = JSON.parse(atob(payload))["email"];
                // setTimeout(() => {
                //   this.rest
                //     .updateUserSession({
                //       email: payloadObj,
                //       session_id: this.localStorage.getItem("session_id"),
                //     })
                //     .subscribe((res) => {
                //       console.log("Result Update Session when focus input:", res);
                //     });
                // }, 1500);
            }
        }
        else {
            this.router.navigate(["apply"]);
        }
    };
    // @TraceMethodDecorator()
    ApplicationComponent.prototype.ngOnInit = function () {
        this.sessionId = localStorage.getItem("session_id");
        this.onSetDateLaoNewYear();
        this.setFormLocalStorage = this.localStorage.getItem("previousData")
            ? JSON.parse(this.localStorage.getItem("previousData"))
            : this.setFormLocalStorage;
        //  this.minDate.year = Number(moment().add(5, "d").format("YYYY"));
        //  this.minDate.month = Number(moment().add(5, "d").format("MM"));
        //  this.minDate.day = Number(moment().add(5, "d").format("DD"));
        this.today.year = Number(moment().format("YYYY"));
        this.today.month = Number(moment().format("MM"));
        this.today.day = Number(moment().format("DD"));
        this.next_2month.year = Number(moment().add(60, "d").format("YYYY"));
        this.next_2month.month = Number(moment().add(60, "d").format("MM"));
        this.next_2month.day = Number(moment().add(60, "d").format("DD"));
        this.personForm = this.formBuilder.group({
            firstName: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z ,-.']*")],
            ],
            lastName: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z ,-.']*")],
            ],
            gender: ["", [Validators.required]],
            DOB: ["", [Validators.required]],
            countryBirth: ["", [Validators.required]],
            tel: ["", [Validators.required, Validators.pattern("^[0-9+]{6}[0-9]+$")]],
            occupation: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            resAddress: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ',.#]*")],
            ],
            province: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            countryCity: ["", [Validators.required]],
            next_destination: [" ", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
            passportNumber: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
            ],
            issueDate: ["", [Validators.required]],
            expiredDate: ["", [Validators.required]],
            countryOfPassport: ["", [Validators.required]],
            visaType: ["1", [Validators.required]],
            intendedDate: ["", [Validators.required]],
            vehicle: ["", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
            purposeOfTrip: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            addressDuringVisit: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ',]*")],
            ],
            acceptTerm: ["", [Validators.required]],
            myfileFlight: ["", []],
            myfileHotel: ["", []],
            ThirdCountryVisa: ["", []],
            guarantor: ["", []],
            bank_statement: ["", []],
        });
        // alert(this.cookieService.get('p_id'));
        this.person = this.formDataService.getFormData();
        this.custImage = this.formDataService.getFormData();
        this.person.currentActiveForm = "1";
        $(document).ready(function () {
            $("#warningUploadPassport").hide();
            $("#warningUploadPhoto").hide();
            $("#btnContinue").click(function () {
                if ($("#inputPassportFile")[0].files.length === 0) {
                    $("#warningUploadPassport").show();
                }
                if ($("#inputPhotoFile")[0].files.length === 0) {
                    $("#warningUploadPhoto").show();
                }
            });
            $("#btnshowModalPassport").click(function () {
                $("#warningUploadPassport").hide();
            });
            $("#btnshowModalPhoto").click(function () {
                $("#warningUploadPhoto").hide();
            });
            $("#btnshowModalPassportCover").click(function () {
                $("#warningUploadPassportCover").hide();
            });
        });
    };
    ApplicationComponent.prototype.onActivate = function (event) {
        this.window.scroll(0, 0);
    };
    ApplicationComponent.prototype.scrollToFirstInvalidControl = function () {
        var firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
        // firstInvalidControl.focus(); //without smooth behavior
        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth",
        });
    };
    ApplicationComponent.prototype.getTopOffset = function (controlEl) {
        var labelOffset = 150;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    };
    ApplicationComponent.prototype.checkDuplicate = function () {
        var _this = this;
        var authInfo = JSON.parse(this.localStorage.getItem(environment.localAuthenInfo));
        if (this.personForm.invalid) {
            this.personForm.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        }
        var _a = this.formDataService.getPerson(), passportNumber = _a.passportNumber, countryOfPassport = _a.countryOfPassport;
        this.verifyToken(authInfo.token);
        this.rest
            .CheckDuplicate({
            passportNumber: passportNumber,
            countryOfPassport: countryOfPassport,
        })
            .subscribe(function (result) {
            if (result.status == "success") {
                _this.onSubmit(_this.personForm);
                _this.data.session_id = _this.localStorage.getItem("session_id");
                _this.data.input_id = "NextToReview";
                _this.data.input_value = "continue_to_review";
                // this.rest.updateUserSession(this.data).subscribe((res) => {
                //   console.log("Result Update Session:", res);
                // });
            }
            else {
                _this.dialog
                    .open(PopUpComponent, {
                    data: result.data,
                    panelClass: "mat-popup-container",
                })
                    .afterClosed()
                    .subscribe(function (confirm) {
                    console.log("Confirm duplicate:", confirm);
                    if (confirm == "continue") {
                        _this.onSubmit(_this.personForm);
                        _this.data.session_id = _this.localStorage.getItem("session_id");
                        _this.data.input_id = "NextToReview";
                        _this.data.input_value = "continue_to_review";
                        // this.rest.updateUserSession(this.data).subscribe((res) => {
                        //   console.log("Result Update Session:", res);
                        // });
                    }
                });
            }
        });
    };
    ApplicationComponent.prototype.updateSessionTrack = function (input, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ApplicationComponent.prototype.clickPopUp = function () {
        var data = [
            {
                application_id: "KK14OMT",
                country_of_passport: 10,
                created_date: "2024-01-25T04:49:27.000Z",
                expired_date: "2028-03-11T17:00:00.000Z",
                intended_date: "2024-01-30T17:00:00.000Z",
                issue_date: "2024-01-24T17:00:00.000Z",
                passport_number: "MEQU2875497",
                status: "Active",
                visa_status: "Pending",
            },
            {
                application_id: "KK14OMT",
                country_of_passport: 10,
                created_date: "2024-01-25T04:49:27.000Z",
                expired_date: "2028-03-11T17:00:00.000Z",
                intended_date: "2024-01-30T17:00:00.000Z",
                issue_date: "2024-01-24T17:00:00.000Z",
                passport_number: "MEQU2875497",
                status: "Active",
                visa_status: "Pending",
            },
        ];
        console.log("Click me!");
        this.dialog
            .open(PopUpComponent, {
            data: data,
            panelClass: "mat-dialog-container",
        })
            .afterClosed()
            .subscribe(function (confirm) {
            console.log("Confirm duplicate:", confirm);
        });
    };
    ApplicationComponent.prototype.onSetDateLaoNewYear = function () {
        var dateAdd = {
            "2024-04-09": 15,
            "2024-04-10": 14,
            "2024-04-11": 13,
            "2024-04-12": 12,
            "2024-04-13": 11,
            "2024-04-14": 10,
            "2024-04-15": 9,
            "2024-04-16": 8,
            "2024-04-17": 7,
            "2024-04-18": 6,
        }[moment().format("YYYY-MM-DD")] || 5;
        var newDate = moment().add(dateAdd, "days");
        this.minDate.year = Number(newDate.format("YYYY"));
        this.minDate.month = Number(newDate.format("MM"));
        this.minDate.day = Number(newDate.format("DD"));
    };
    ApplicationComponent.prototype.onSetUnfinishedForm = function (event, key) {
        if (key == "firstName") {
            this.setFormLocalStorage.firstName = event.target.value;
        }
        else if (key == "lastName") {
            this.setFormLocalStorage.lastName = event.target.value;
        }
        else if (key == "telephone") {
            this.setFormLocalStorage.telephone = event.target.value;
        }
        else if (key == "occupation") {
            this.setFormLocalStorage.occupation = event.target.value;
        }
        else if (key == "resAddress") {
            this.setFormLocalStorage.residentialAddress = event.target.value;
        }
        else if (key == "province") {
            this.setFormLocalStorage.stateProvince = event.target.value;
        }
        else if (key == "passportNo") {
            this.setFormLocalStorage.passportNum = event.target.value;
        }
        else if (key == "vehicle") {
            this.setFormLocalStorage.vehicle = event.target.value;
        }
        else if (key == "addrDuringVisit") {
            this.setFormLocalStorage.addressDuringVisit = event.target.value;
        }
        else if (key == "nextDestination") {
            this.setFormLocalStorage.nextDestination = event.target.value;
        }
        this.onSetToLocalStorage(this.setFormLocalStorage);
    };
    ApplicationComponent.prototype.onSetToLocalStorage = function (data) {
        this.localStorage.setItem("previousData", JSON.stringify(data));
    };
    ApplicationComponent.prototype.reUploadPhoto = function (base64) {
        var _this = this;
        var formData = new FormData();
        formData.append("upload_file_photo", base64);
        formData.append("version", "2");
        this.rest.uploadPhotoFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (Array.isArray(result)) {
                    this.person.imgPhotoName = result[0].name;
                }
                else {
                    this.person.imgPhotoName = result.name;
                }
                return [2 /*return*/];
            });
        }); });
    };
    ApplicationComponent.prototype.reUploadPassport = function (base64) {
        var _this = this;
        var formData = new FormData();
        formData.append("upload_file_passport", base64);
        this.rest.uploadPassportFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.person.imgPassName = result.name;
                return [2 /*return*/];
            });
        }); });
    };
    return ApplicationComponent;
}());
export { ApplicationComponent };
